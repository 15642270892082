import './App.scss';
import 'react-datepicker/dist/react-datepicker.css';
import LayoutContainer from './components/LayoutContainer/LayoutContainer';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Homepage from './components/HomePage/HomePage';
import BasicUserInfo from './components/user/BasicUserInfo/BasicUserInfo';
import DemographicDetails from './components/user/DemographicDetails/DemographicDetails';
import { UserProvider } from './context/UserContext';
import { SurveyProvider } from './context/SurveyContext';
import Survey from './components/Survey/Survey';
import AgeGate from './components/user/AgeGate/AgeGate';
import NeurotypeSurvey from './components/NeurotypeSurvey/NeurotypeSurvey';
import { NeurotypeSurveyProvider } from './context/NeurotypeSurveyContext';
import NeurotypeDetail from './components/NeurotypeDetail/NeurotypeDetail';
import Intro from './components/Intro/Intro';
import LoadResults from './components/LoadResults/LoadResults';
import { FC, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';

const MainApp: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentComponent, setCurrentComponent] = useState(() => {
    return localStorage.getItem('currentComponent') || 'homepage';
  });

  const handleNavigate = (componentName: string) => {
    setCurrentComponent(componentName);
    localStorage.setItem('currentComponent', componentName);
    navigate(`/${componentName}`);
  };

  useEffect(() => {
    const component = location.pathname.slice(1) || 'homepage';
    setCurrentComponent(component);
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      localStorage.setItem('currentComponent', currentComponent);
    });

    return () => {
      window.removeEventListener('beforeunload', () => {
        localStorage.setItem('currentComponent', currentComponent);
      });
    };
  }, [currentComponent]);

  const renderComponent = () => {
    switch (currentComponent) {
      case 'homepage':
        return <Homepage onNavigate={handleNavigate} />;
      case 'ageGate':
        return <AgeGate onNavigate={handleNavigate} />;
      case 'basicUserInfo':
        return <BasicUserInfo onNavigate={handleNavigate} />;
      case 'demographicDetails':
        return <DemographicDetails onNavigate={handleNavigate} />;
      case 'surveyIntro':
        return <Intro onNavigate={handleNavigate} />;
      case 'neurotypeSurvey':
        return (
          <NeurotypeSurveyProvider>
            <NeurotypeSurvey onNavigate={handleNavigate} />
          </NeurotypeSurveyProvider>
        );
      case 'survey':
        return (
          <SurveyProvider>
            <Survey onNavigate={handleNavigate} />
          </SurveyProvider>
        );
      case 'loadResults':
        return <LoadResults onNavigate={handleNavigate} />;
      case 'neurotypeDetail':
        return <NeurotypeDetail onNavigate={handleNavigate} />;
      default:
        return <NotFoundPage />;
    }
  };

  return (
    <UserProvider>
      <LayoutContainer>
        <ErrorBoundary>
          <div className='survey-transition-item w-full'>
            {renderComponent()}
          </div>
        </ErrorBoundary>
      </LayoutContainer>
    </UserProvider>
  );
};

const App: FC = () => {
  return (
    <Router>
      <Routes>
        <Route path='/*' element={<MainApp />} />
      </Routes>
    </Router>
  );
};

export default App;
