import React, { ReactNode } from 'react';
import Title from '../Title/Title';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  const handleOverlayClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    // Closes modal if the click is on the overlay
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  // Return nothing if not open
  if (!isOpen) return null;

  return (
    <div
      id='modal'
      className='blurred fixed top-0 right-0 bottom-[72px] left-0 flex justify-center items-start h-100 w-full mx-auto p-7 z-20'
      onClick={handleOverlayClick}
    >
      <div className='flex flex-col h-full lg:outline lg:outline-white rounded-lg lg:px-5 lg:pt-6 lg:pb-2 w-full lg:w-full lg:max-w-[61.875rem] relative'>
        <div className='hidden lg:flex'>
          <Title horizontal={true} />
        </div>

        <div className='flex flex-col h-full lg:px-[7.563rem] lg:py-12'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
