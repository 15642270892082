import React, {
  createContext,
  useContext,
  useReducer,
  useCallback,
  ReactNode,
} from 'react';
import { Answer } from '../types/answer';
import axios from 'axios';
import { useUser } from './UserContext';

interface NeurotypeSurveyResponse {
  answers: Answer[];
  currentQuestionIndex: number;
}

interface NeurotypeSurveyState {
  surveyResponse: NeurotypeSurveyResponse;
}

interface UpdateAnswerAction {
  type: 'UPDATE_ANSWER';
  payload: Answer;
}

interface SetCurrentQuestionIndexAction {
  type: 'SET_CURRENT_QUESTION_INDEX';
  payload: number;
}

type SurveyAction = UpdateAnswerAction | SetCurrentQuestionIndexAction;

const surveyReducer = (
  state: NeurotypeSurveyState,
  action: SurveyAction,
): NeurotypeSurveyState => {
  switch (action.type) {
    case 'UPDATE_ANSWER':
      const updatedAnswers = state.surveyResponse.answers.filter(
        (a) => a.questionId !== action.payload.questionId,
      );
      updatedAnswers.push(action.payload);
      localStorage.setItem(
        'neurotypeSurveyAnswers',
        JSON.stringify(updatedAnswers),
      );
      return {
        ...state,
        surveyResponse: {
          ...state.surveyResponse,
          answers: updatedAnswers,
        },
      };
    case 'SET_CURRENT_QUESTION_INDEX':
      localStorage.setItem(
        'currentNeurotypeSurveyQuestionIndex',
        action.payload.toString(),
      );
      return {
        ...state,
        surveyResponse: {
          ...state.surveyResponse,
          currentQuestionIndex: action.payload,
        },
      };
    default:
      return state;
  }
};

const NeurotypeSurveyContext = createContext<
  | {
      state: NeurotypeSurveyState;
      dispatch: React.Dispatch<SurveyAction>;
      saveSurveyResponse: () => void;
    }
  | undefined
>(undefined);

export const NeurotypeSurveyProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const initialState: NeurotypeSurveyState = {
    surveyResponse: {
      answers: JSON.parse(
        localStorage.getItem('neurotypeSurveyAnswers') || '[]',
      ),
      currentQuestionIndex:
        Number(localStorage.getItem('currentNeurotypeSurveyQuestionIndex')) ||
        0,
    },
  };

  const [state, dispatch] = useReducer(surveyReducer, initialState);

  const { setUser } = useUser();

  const saveSurveyResponse = useCallback(async () => {
    try {
      if (!process.env.REACT_APP_SURVEY_ENDPOINT) {
        throw new Error('SURVEY_ENDPOINT is not defined');
      }

      const storedUser = localStorage.getItem('user') || '';
      const user = JSON.parse(storedUser);

      if (!storedUser) {
        throw new Error('User not found in LocalStorage');
      }

      const surveyResponse = {
        ...state.surveyResponse,
        userId: user.id,
      };

      axios
        .post(process.env.REACT_APP_SURVEY_ENDPOINT, {
          neurotypeSurvey: surveyResponse,
        })
        .then((response) => {
          if (response?.data?.neurotypeName) {
            const neurotypeName = response.data.neurotypeName;

            setUser({
              ...user,
              neurotypeName,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, [state.surveyResponse, setUser]);

  return (
    <NeurotypeSurveyContext.Provider
      value={{ state, dispatch, saveSurveyResponse: saveSurveyResponse }}
    >
      {children}
    </NeurotypeSurveyContext.Provider>
  );
};

export const useSurvey = () => {
  const context = useContext(NeurotypeSurveyContext);
  if (!context) {
    throw new Error('useSurvey must be used within a NeurotypeSurveyProvider');
  }
  return context;
};
