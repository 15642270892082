import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from 'react';
import { User } from '../types/user';
import axios from 'axios';
import { getValues } from '../utils/valuable';

interface UserContextType {
  user: User;
  setUser: (user: User) => void;
  triggerSave: () => void;
}

const defaultUser: User = {
  minor: undefined,
  firstName: '',
  lastName: '',
  email: '',
  yearsOld: undefined,
  consent: false,
  sexAssignedAtBirth: '',
  races: [],
  ethnicity: '',
  education: '',
};

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [user, setUser] = useState<User>(defaultUser);
  const [shouldSave, setShouldSave] = useState(false);

  const saveUserProfile = useCallback(async (userData: User) => {
    if (!process.env.REACT_APP_SURVEY_ENDPOINT) {
      console.error('SURVEY_ENDPOINT is not defined');
      return;
    }

    try {
      const response = await axios.post(process.env.REACT_APP_SURVEY_ENDPOINT, {
        user: getValues(userData),
      });
      // Handle the response inside here if necessary, such as updating the user ID
      return response.data.userId;
    } catch (error) {
      console.error('Error saving data:', error);
    }
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  // Update local storage whenever the user state changes
  useEffect(() => {
    if (user && user.id) {
      localStorage.setItem('user', JSON.stringify(user));
    }
  }, [user]);

  useEffect(() => {
    if (shouldSave) {
      if (user.minor && !user.id) {
        // Assume a minor age if not defined to fulfill API requirements.
        user.yearsOld = 1;
      }

      saveUserProfile(user).then((userId) => {
        if (userId) {
          setUser((prev) => ({ ...prev, id: userId }));
        }
        setShouldSave(false);
      });
    }
  }, [shouldSave, user, saveUserProfile]);

  const triggerSave = () => {
    setShouldSave(true);
  };

  return (
    <UserContext.Provider value={{ user, setUser, triggerSave }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
