import React from 'react';
import './Title.scss';

interface TitleProps {
  showTitleText?: boolean;
  horizontal?: boolean;
}

const Title: React.FC<TitleProps> = ({
  showTitleText = false,
  horizontal = false,
}) => {
  const titleClass = showTitleText
    ? ''
    : 'hidden lg:flex lg:justify-end lg:pb-3';

  return (
    <div
      id='app-title'
      className={`flex ${horizontal ? 'flex-row' : 'flex-col'}`}
    >
      <div
        className={`flex flex-grow-0 z-10 ${horizontal ? '' : 'justify-center'}`}
      >
        <img
          src='/images/brain.svg'
          alt='brain'
          className={`flex w-[92px] ${horizontal ? '-lg:mt-2' : ''}`}
        />
      </div>

      <div className={`flex flex-col mt-3 ${titleClass}`}>
        <h1 className='text-3xl lg:text-4xl'>Neuroquest</h1>
        <h2 className='text-xs uppercase text-center leading-4 mt-1.5 font-normal subheading pl-3'>
          A Brain Health Quiz
        </h2>
      </div>
    </div>
  );
};

export default Title;
