import React, { useState } from 'react';
import './Select.scss';
import { Answer } from '../../../types/answer';

export interface SelectProps {
  options: { value: string | number; text: string }[];
  onChange: (value: Answer) => void;
  selectedValue: string[] | number[];
  multiple?: boolean;
  name: string;
  placeholder?: string;
}

const Select: React.FC<SelectProps> = ({
  options,
  onChange,
  selectedValue,
  multiple = false,
  name,
  placeholder = 'Select an option',
}) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { options } = event.target;
    const values = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);

    onChange({
      questionId: name,
      type: 'ANSWERED',
      value: values,
    });

    setIsSelected(values.length > 0 && values[0] !== ''); // Check if a selection has been made
  };

  const valueProp = multiple
    ? selectedValue.map(String) // map values to strings for consistency in the select element
    : selectedValue[0] !== undefined
      ? String(selectedValue[0]) // for single, use the first element or an empty string
      : '';

  const colorStyle = isSelected ? 'color-blue' : 'color-grey'; // Conditional class assignment

  return (
    <div className='w-full inline-block relative'>
      <select
        name={name}
        multiple={multiple}
        onChange={handleChange}
        value={valueProp}
        className={`select-custom ${colorStyle} w-full px-2.5 py-1 min-h-[35px] rounded-full cursor-pointer`}
        style={{ backgroundImage: 'url(/images/icons/chevron-down.svg)' }}
      >
        {placeholder && !multiple && selectedValue.length === 0 && (
          <option value='' disabled className='placeholder-option'>
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option
            key={option.value.toString()}
            value={option.value.toString()}
            className='option-label'
          >
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
