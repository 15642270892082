import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSurvey } from '../../context/SurveyContext';
import Question from '../Question/Question';
// import neurotypeData from '../../data/neurotypes.json';
import questionnaireData from '../../data/questionnaire.json';
import { Answer } from '../../types/answer';
import { isVisible } from '../../utils/surveyUtils';
import Header from '../Header/Header';
import Title from '../Title/Title';
import { ComponentKey } from '../../types/component-key';
import ProgressTracker from '../ProgressTracker/ProgressTracker';
import VerticalLine from '../VerticalLine/VerticalLine';
import VideoBackground from '../VideoBackground/VideoBackground';

interface SurveyProps {
  onNavigate: (componentName: ComponentKey) => void;
}

const Survey: React.FC<SurveyProps> = ({ onNavigate }) => {
  const { state, dispatch, saveSurveyResponse } = useSurvey();
  const { startedAt, currentQuestionIndex } = state;

  const [errors, setErrors] = useState<Record<string, string>>({});
  const nodeRef = useRef(null);

  useEffect(() => {
    if (!startedAt) {
      dispatch({
        type: 'UPDATE_STARTED_AT',
        payload: new Date().toISOString(),
      });
    }

    const initialAnswers: Answer[] = questionnaireData.components
      .filter((component) => component.choices?.length)
      .map((component) => ({
        questionId: component.id,
        type: 'NOT_PRESENTED',
        value: [],
      }));

    const initialErrors: Record<string, string> = {};
    initialAnswers.forEach((answer) => {
      initialErrors[answer.questionId] = '';
    });

    setErrors(initialErrors);
  }, [dispatch, startedAt]);

  const translations =
    questionnaireData.localizations.find((loc) => loc.locale === 'en_US')
      ?.translations || [];

  const getTranslation = (key: string): string => {
    return translations.find((trans) => trans.key === key)?.translation || key;
  };

  const visibleQuestions = useMemo(() => {
    return questionnaireData.components.filter(
      (question) =>
        question.choices?.length &&
        isVisible(question, state.surveyResponse.answers),
    );
  }, [state.surveyResponse.answers]);

  const currentQuestion = useMemo(() => {
    return visibleQuestions[currentQuestionIndex];
  }, [visibleQuestions, currentQuestionIndex]);

  const isAnswerSelected = useMemo(() => {
    const answer = state.surveyResponse.answers.find(
      (ans) => ans.questionId === currentQuestion?.id,
    )?.value;
    return Array.isArray(answer) ? answer.length > 0 : !!answer;
  }, [state.surveyResponse.answers, currentQuestion]);

  const isLastQuestion = currentQuestionIndex === visibleQuestions.length - 1;

  const answerForCurrentQuestion = useMemo(() => {
    return state.surveyResponse.answers.filter(
      (ans) => ans.questionId === currentQuestion?.id,
    );
  }, [state.surveyResponse.answers, currentQuestion?.id]);

  const handleAnswerChange = (answer: Answer) => {
    const errorMessage =
      Array.isArray(answer.value) && answer.value.length === 0
        ? 'Required.'
        : '';

    setErrors((prevErrors) => ({
      ...prevErrors,
      [answer.questionId]: errorMessage,
    }));

    dispatch({ type: 'UPDATE_ANSWER', payload: answer });
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleContinue(event);
  };

  const handleContinue = async (event: React.FormEvent) => {
    event.preventDefault();

    if (isLastQuestion) {
      saveSurveyResponse();
      onNavigate('loadResults' as ComponentKey);
    } else {
      dispatch({
        type: 'SET_CURRENT_QUESTION_INDEX',
        payload: currentQuestionIndex + 1,
      });
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      dispatch({
        type: 'SET_CURRENT_QUESTION_INDEX',
        payload: currentQuestionIndex - 1,
      });
    } else {
      onNavigate('neurotypeSurvey' as ComponentKey);
    }
  };

  const questionCount = useMemo(() => {
    return 4 + visibleQuestions.length;
  }, [visibleQuestions]);

  const overallQuestionIndex = useMemo(() => {
    return 4 + currentQuestionIndex;
  }, [currentQuestionIndex]);

  return (
    <div className='relative min-h-full'>
      {/* Main video background */}
      <VideoBackground />

      {/* Content */}
      <div ref={nodeRef} className='survey-transition-item'>
        <div
          id='brain-health-survey'
          className='flex flex-col justify-start lg:justify-center lg:items-center w-full h-full'
          style={{ minHeight: 'calc(100vh - 75px)' }}
        >
          <div className='lg:outline lg:outline-white rounded-lg px-12 lg:px-5 pt-8 lg:pt-6 lg:pb-16 w-full lg:w-full lg:max-w-[61.875rem] relative'>
            <div className='flex flex-row'>
              <div className='flex-1'>
                <div className='flex w-full lg:hidden'>
                  <Header
                    progressInfo={{
                      totalQuestionsCount: questionCount,
                      currentQuestionIndex: overallQuestionIndex,
                    }}
                  />
                </div>

                <div className='hidden lg:flex'>
                  <Title horizontal={true} />
                </div>
              </div>

              <div className='flex-1 hidden lg:flex lg:justify-end'>
                <div className='pt-3 pr-9'>
                  <ProgressTracker
                    totalQuestionsCount={questionCount}
                    currentQuestionIndex={overallQuestionIndex}
                  />
                </div>
              </div>
            </div>

            <VerticalLine />

            <div className='lg:flex lg:flex-col lg:justify-center lg:align-center lg:px-10'>
              <form onSubmit={handleFormSubmit}>
                {currentQuestion && (
                  <>
                    <Question
                      question={{
                        id: currentQuestion.id,
                        label: getTranslation(
                          currentQuestion.promptI18nKey || '',
                        ),
                        choices:
                          currentQuestion.choices?.map((choice) => ({
                            value: choice.value,
                            text: getTranslation(choice.choiceI18nKey),
                          })) || [],
                        questionType:
                          (currentQuestion.type as 'SINGLE_SELECT') ||
                          'MULTI_SELECT',
                      }}
                      currentIndex={currentQuestionIndex}
                      totalQuestions={visibleQuestions.length}
                      answers={answerForCurrentQuestion}
                      onChange={handleAnswerChange}
                      error={errors[currentQuestion.id]}
                    />

                    <div className='grid grid-cols-1 lg:grid-cols-2'>
                      <div></div>
                      <div className='flex flex-col justify-start lg:justify-center items-center m-12 lg:mt-0'>
                        <div className='lg:flex lg:flex-col lg:justify-center lg:align-center lg:pl-10'>
                          <button
                            type='button'
                            onClick={handleContinue}
                            disabled={!isAnswerSelected}
                          >
                            Continue
                          </button>

                          <div
                            className='text-center underline text-sm mt-2.5 back-link'
                            onClick={handleBack}
                          >
                            Back
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Survey;
