import React, { useRef } from 'react';
import { Answer } from '../../../types/answer';
import { useIsMultiline } from '../../../utils/use-is-multiline';

interface RadioInputProps {
  name: string;
  value: string | number;
  checked: boolean;
  onChange: (value: Answer) => void;
  text: string;
}
const RadioInput: React.FC<RadioInputProps> = ({
  name,
  value,
  checked,
  onChange,
  text,
}) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const isMultiline = useIsMultiline(text, textRef);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      questionId: name,
      type: 'ANSWERED',
      value: value,
    });
  };

  return (
    <label
      className={`min-h-[35px] blurred w-full flex cursor-pointer font-semibold py-2 px-4 border border-purple mt-5 option-container ${checked ? 'checked' : 'not-checked'} ${isMultiline ? 'multiline' : 'rounded-lg'}`}
      onClick={(e) => e.stopPropagation()}
    >
      <input
        type='radio'
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
        className='hidden'
      />
      <span ref={textRef} className='text'>
        {text}
      </span>
    </label>
  );
};

export default RadioInput;
