import jsonLogic from 'json-logic-js';
import { Answer } from '../types/answer';
import { SurveyQuestion } from '../types/survey-question';

export const isVisible = (
  question: SurveyQuestion,
  answers: Answer[],
): boolean => {
  if (!question.visible) {
    return true; // Show the question by default if no visibility condition is set
  }

  const context = createContext(answers);

  const result = jsonLogic.apply(question.visible, context);

  return result;
};

// Create a context from answers that we can feed to jsonLogic.apply

export const createContext = (
  answers: Answer[],
): { answers: Record<string, (string | number)[]> } => {
  return {
    answers: answers.reduce<Record<string, (string | number)[]>>(
      (ctx, answer) => {
        // Ensure answer.value is always treated as an array
        const normalizedValue = Array.isArray(answer.value)
          ? answer.value
          : [answer.value];

        ctx[answer.questionId] = normalizedValue;
        return ctx;
      },
      {},
    ),
  };
};
