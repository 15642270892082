import React from 'react';
import Title from '../Title/Title';
import { useUser } from '../../context/UserContext';
import './LoadResults.scss';
import { ComponentKey } from '../../types/component-key';

interface LoadResultsProps {
  onNavigate: (componentName: ComponentKey) => void;
}
const LoadResults: React.FC<LoadResultsProps> = ({ onNavigate }) => {
  const { user } = useUser();

  const handleOnClick = () => {
    onNavigate('neurotypeDetail' as ComponentKey);
  };

  return (
    <div
      id='load-results'
      className='flex flex-col justify-center items-center w-full h-full'
      style={{ minHeight: 'calc(100vh - 75px)' }}
      onClick={() => onNavigate('neurotypeDetail' as ComponentKey)}
    >
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='lg:outline lg:outline-white lg:rounded-lg px-12 lg:px-[6.344rem] pt-8 lg:pb-9 lg:w-full lg:max-w-[36.125rem]'>
          <div className='block lg:hidden'>
            <Title showTitleText={false} />
          </div>
          <div className='hidden lg:block mb-3'>
            <Title showTitleText={true} />
          </div>

          <div className=' mx-auto rounded-lg px-5 py-6 -mt-6 lg:mt-0 blurred'>
            <div className='lg:pl-5'>
              <div className='text-3xl mb-2  text-center uppercase'>
                <h1>Great Job!</h1>
              </div>
            </div>

            <p className='text-center mb-3 leading-5'>
              Stay tuned while we analyze your results and prepare your
              personalized brain fitness plan.
            </p>
          </div>

          {!user.surveyResults ? (
            <div className='flex justify-center mt-10'>
              <img
                src='/images/icons/loading.gif'
                alt='loading symbol'
                className='w-5/6'
              />
            </div>
          ) : null}

          <div className='flex justify-center m-16'>
            <button disabled={!user.surveyResults} onClick={handleOnClick}>
              results
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadResults;
