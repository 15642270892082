import React, {
  createContext,
  useContext,
  useReducer,
  useCallback,
  ReactNode,
  useEffect,
} from 'react';

import { Answer } from '../types/answer';
import axios from 'axios';
import { useUser } from './UserContext';
import jsonData from '../data/questionnaire.json';
import { v4 as uuidv4 } from 'uuid';

interface SurveyResponse {
  startTime: string;
  endTime: string;
  answers: Answer[];
  audienceType: string;
  deviceInfo: {
    deviceType: string;
    installationId?: string;
    userAgent: string;
  };
  questionnaireId: string;
  questionnaireVersion: string;
}

interface SurveyState {
  surveyResponse: SurveyResponse;
  startedAt: string;
  currentQuestionIndex: number;
}

interface UpdateAnswerAction {
  type: 'UPDATE_ANSWER';
  payload: Answer;
}

interface SetCurrentQuestionIndexAction {
  type: 'SET_CURRENT_QUESTION_INDEX';
  payload: number;
}

interface UpdateStartedAtAction {
  type: 'UPDATE_STARTED_AT';
  payload: string;
}

type SurveyAction =
  | UpdateAnswerAction
  | SetCurrentQuestionIndexAction
  | UpdateStartedAtAction;

const surveyReducer = (
  state: SurveyState,
  action: SurveyAction,
): SurveyState => {
  switch (action.type) {
    case 'UPDATE_STARTED_AT':
      return {
        ...state,
        startedAt: action.payload,
      };

    case 'UPDATE_ANSWER':
      const updatedAnswers = state.surveyResponse.answers.filter(
        (a) => a.questionId !== action.payload.questionId,
      );
      updatedAnswers.push(action.payload);
      localStorage.setItem('surveyAnswers', JSON.stringify(updatedAnswers));
      return {
        ...state,
        surveyResponse: {
          ...state.surveyResponse,
          answers: updatedAnswers,
        },
      };

    case 'SET_CURRENT_QUESTION_INDEX':
      localStorage.setItem(
        'currentSurveyQuestionIndex',
        action.payload.toString(),
      );
      return {
        ...state,
        currentQuestionIndex: action.payload,
      };

    default:
      return state;
  }
};

const SurveyContext = createContext<
  | {
      state: SurveyState;
      dispatch: React.Dispatch<SurveyAction>;
      saveSurveyResponse: () => void;
    }
  | undefined
>(undefined);

export const SurveyProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const initialState: SurveyState = {
    surveyResponse: {
      startTime: '',
      endTime: '',
      answers: JSON.parse(localStorage.getItem('surveyAnswers') || '[]'),
      audienceType: 'SUBJECT',
      deviceInfo: {
        deviceType: '',
        installationId: undefined,
        userAgent: '',
      },
      questionnaireId: jsonData.id,
      questionnaireVersion: jsonData.version,
    },
    startedAt: '',
    currentQuestionIndex:
      Number(localStorage.getItem('currentSurveyQuestionIndex')) || 0,
  };

  const [state, dispatch] = useReducer(surveyReducer, initialState);
  const { setUser } = useUser();

  const getDeviceInfo = useCallback(() => {
    const deviceType = getDeviceType();
    const userAgent = navigator.userAgent;
    return {
      deviceType,
      userAgent,
      installationId: uuidv4(),
    };
  }, []);

  function getDeviceType() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/i.test(
        ua,
      )
    ) {
      return 'mobile';
    }
    return 'web';
  }

  const fetchSurveyResults = useCallback(
    async (userId: string) => {
      try {
        if (!process.env.REACT_APP_SURVEY_ENDPOINT) {
          throw new Error('SURVEY_ENDPOINT is not defined');
        }

        const resultsResponse = await axios.post(
          process.env.REACT_APP_SURVEY_ENDPOINT,
          {
            results: true,
            userId: userId,
          },
        );

        console.log(resultsResponse.data);

        const surveyResults = resultsResponse.data.surveyResults;
        localStorage.setItem('surveyResults', JSON.stringify(surveyResults));

        const storedUser = localStorage.getItem('user') || '';
        const user = JSON.parse(storedUser);

        setUser({
          ...user,
          surveyResults,
        });
      } catch (error) {
        console.error('Error fetching survey results:', error);
      }
    },
    [setUser],
  );

  const saveSurveyResponse = useCallback(async () => {
    try {
      if (!process.env.REACT_APP_SURVEY_ENDPOINT) {
        throw new Error('SURVEY_ENDPOINT is not defined');
      }

      const storedUser = localStorage.getItem('user') || '';
      const user = JSON.parse(storedUser);

      if (!storedUser) {
        throw new Error('User not found in LocalStorage');
      }

      const surveyResponse = {
        ...state.surveyResponse,
        userId: user.id,
        startTime: state.startedAt,
        deviceInfo: getDeviceInfo(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        locale: navigator.language,
        endTime: new Date().toISOString(),
      };

      await axios
        .post(process.env.REACT_APP_SURVEY_ENDPOINT, {
          survey: surveyResponse,
        })
        .then(async (response) => {
          if (response.data.success) {
            await fetchSurveyResults(user.id);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, [
    state.surveyResponse,
    state.startedAt,
    getDeviceInfo,
    fetchSurveyResults,
  ]);

  useEffect(() => {
    if (!state.startedAt) {
      dispatch({
        type: 'UPDATE_STARTED_AT',
        payload: new Date().toISOString(),
      });
    }
  }, [state.startedAt]);

  return (
    <SurveyContext.Provider value={{ state, dispatch, saveSurveyResponse }}>
      {children}
    </SurveyContext.Provider>
  );
};

export const useSurvey = () => {
  const context = useContext(SurveyContext);
  if (!context) {
    throw new Error('useSurvey must be used within a SurveyProvider');
  }
  return context;
};
