import React, { useState } from 'react';
import jsonData from '../../data/neurotypes.json';
import { Neurotype } from '../../types/neurotype';
import { useUser } from '../../context/UserContext';

import Header from '../Header/Header';
import './NeurotypeDetail.scss';
import { ComponentKey } from '../../types/component-key';
import Title from '../Title/Title';
import VerticalLine from '../VerticalLine/VerticalLine';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

interface HomepageProps {
  onNavigate: (componentName: ComponentKey) => void;
}

const NeurotypeDetail: React.FC<HomepageProps> = ({ onNavigate }) => {
  const { user } = useUser();

  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  let neurotypeName: string | undefined | null;
  if (!user?.neurotypeName) {
    onNavigate('homepage');
  }

  if (!(user.surveyResults && user.surveyResults.riskFactors)) {
    onNavigate('homepage');
  }

  neurotypeName = user.neurotypeName;

  const neurotypes: Neurotype[] = jsonData.components;
  const neurotype = neurotypes.find((nt) => nt.name === neurotypeName);
  if (!neurotype) return null;

  const riskFactors = user.surveyResults?.riskFactors || [];

  const recommendations = neurotype.recommendations
    .filter((recommendation) => riskFactors.includes(recommendation.value))
    .map((rec) => ({
      title: rec.title,
      content: rec.text,
    }));

  const contentSections = [
    {
      title: 'More about your Neurotype',
      content: neurotype.description,
    },
    ...recommendations,
  ];

  const toggleIndex = (index: number): void => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const shareResults = async () => {
    const actionPlanUrl = `${window.location.origin}/${neurotype.name.toLowerCase()}.html`;

    if (navigator.share) {
      try {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'shared',
          neurotype: neurotype.name,
        });
        await navigator.share({
          title: document.title,
          text: `I discovered my Neurotype is  ${neurotype.name}! Take this 5 min quiz to learn your unique Neurotype and how to boost your brain health and perform at your best.`,
          url: actionPlanUrl,
        });
      } catch (error) {
        console.error('Error sharing the content', error);
      }
    } else {
      alert('Sharing is not supported in this browser.');
    }
  };

  let showShare = true;
  if (!navigator.canShare) {
    showShare = false;
  }

  return (
    <div className='flex flex-col justify-between lg:justify-start'>
      <div
        id='neurotype'
        className='flex flex-col justify-start lg:justify-center lg:items-center w-full h-full'
        style={{ minHeight: 'calc(100vh - 75px)' }}
      >
        <div className='lg:outline lg:outline-white rounded-lg px-3.5 lg:pl-10 lg:pr-6 pt-8 lg:pt-6 lg:pb-16 w-full lg:w-full lg:max-w-[61.875rem] relative'>
          <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-6'>
            <div className='flex flex-col lg:pr-10'>
              <div className='lg:flex'>
                <div className='flex w-full lg:hidden'>
                  <Header />
                </div>

                <div className='hidden lg:flex'>
                  <Title horizontal={true} />
                </div>
              </div>

              <div className='flex flex-col lg:justify-between lg:h-full'>
                <div className='flex flex-col lg:align-center lg:h-full'>
                  <div className='text-lg text-center font-semibold mb-2 lg:mt-6'>
                    Your Neurotype is
                  </div>

                  <div className='lg:flex lg:flex-col lg:justify-center h-full'>
                    <div className='relative'>
                      <img
                        src={neurotype?.image}
                        alt={neurotype?.name}
                        className='rounded-lg'
                      />

                      <div className='flex justify-center align-center w-full share-prompt absolute'>
                        {showShare ? (
                          <button className='white-btn rounded-full'>
                            <div className='mr-3 lg:pl-4 normal-case text-semibold text-base'>
                              Share
                            </div>

                            <img
                              src='/images/icons/curved-arrow.svg'
                              alt='Share icon'
                              className=''
                              onClick={shareResults}
                            />
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='blurred rounded-lg text-4xl tagline mb-5 lg:mb-0 mt-3 lg:mt-0'>
                  {neurotype.headline}
                </div>
              </div>
            </div>

            <VerticalLine />

            <div className='lg:flex lg:flex-col lg:justify-center lg:align-center'>
              <div className='blurred cap rounded-lg'>
                <div className='cap-title font-normal'>Custom Action Plan</div>

                <hr className='border border-white mb-2 mt-2' />

                {contentSections.map((section, index) => (
                  <React.Fragment key={index}>
                    <div onClick={() => toggleIndex(index)}>
                      <div className='flex justify-between items-center cursor-pointer'>
                        <h2 className='text-lg font-bold pt-3 mb-3'>
                          {section.title}
                        </h2>
                        <div className='expander'>
                          <div className='circle-background'></div>
                          <div className='circle'></div>
                          <div
                            className={`triangle ${index === activeIndex ? 'rotate' : ''}`}
                          ></div>
                        </div>
                      </div>
                      <div
                        className='content'
                        style={{
                          maxHeight: index === activeIndex ? '1000px' : '0',
                        }}
                      >
                        <div
                          className='pb-2'
                          dangerouslySetInnerHTML={{
                            __html: section.content || '',
                          }}
                        />
                      </div>
                    </div>
                    <hr className='border border-white mb-2' />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id='satisfaction-survey'
        className='bg-blue text-white py-4 mt-6 lg:mt-4 lg:mb-6 w-full'
      >
        <div className='max-w-4xl mx-auto px-10 lg:pl-20 lg:pr-12 flex items-center justify-between'>
          <div className='font-medium'>
            Please take a moment to answer these 6 short questions to help us
            improve our tool for better brain health.
          </div>
          <button className='white-btn rounded-full px-5 d-flex justify-center align-center'>
            <a
              href='https://forms.gle/BDUdTfFVjZjiRK6N7'
              target='_blank'
              rel='noopener noreferrer'
              className='uppercase'
            >
              Go
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NeurotypeDetail;
