import React from 'react';

const NotFoundPage = () => {
  return (
    <div className='not-found'>
      <h1>404 - Page Not Found</h1>
      <p>
        The page you are looking for might have been removed, had its name
        changed, or is temporarily unavailable.
      </p>
      {/* You can add a link to go back to the home page or any other relevant page */}
      <a href='/'>Go to Home Page</a>
    </div>
  );
};

export default NotFoundPage;
