import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Title from '../Title/Title';
import { ComponentKey } from '../../types/component-key';

interface HomepageProps {
  onNavigate: (componentName: ComponentKey) => void;
}

const Homepage: React.FC<HomepageProps> = ({ onNavigate }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      [
        'user',
        'currentComponent',
        'currentNeurotypeSurveyQuestionIndex',
        'neurotypeSurveyAnswers',
        'currentSurveyQuestionIndex',
        'surveyAnswers',
        'surveyResults',
      ].forEach((storedDatum) => {
        localStorage.removeItem(storedDatum);
      });
    }
  }, [location.pathname]);

  return (
    <div
      id='homepage'
      className='flex flex-col justify-center items-center w-full h-full'
      style={{ minHeight: 'calc(100vh - 75px)' }}
      onClick={() => onNavigate('ageGate' as ComponentKey)}
    >
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='lg:outline lg:outline-white lg:rounded-lg px-12 lg:px-[6.344rem] lg:pt-28 lg:pb-36 lg:f-full lg:max-w-[36.125rem]'>
          <Title showTitleText={true} />
          <div className=' mx-auto rounded-lg px-5 py-6 mt-6 lg:mt-16 blurred'>
            <p className='text-center mb-3 leading-5'>
              Welcome to the NeuroQuest Quiz, the first step in taking charge of your brain health and mental wellness. Your answers to these questions will help us customize a brain fitness program just for you. Let’s get into it!  
            </p>
            <div className='flex justify-center'>
              <img src='/images/icons/chevron-down.svg' alt='chevron' />
            </div>
          </div>
          <div className='flex justify-center mt-16 lg:mt-24'>
            <button>Start</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
