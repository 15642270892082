import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useSurvey } from '../../context/NeurotypeSurveyContext';
import Question from '../Question/Question';
import { Answer } from '../../types/answer';
import { Choice } from '../../types/choice';
import neurotypeData from '../../data/neurotypes.json';
import { Neurotype } from '../../types/neurotype';
import Header from '../Header/Header';
import Title from '../Title/Title';
import { ComponentKey } from '../../types/component-key';
import ProgressTracker from '../ProgressTracker/ProgressTracker';
import VerticalLine from '../VerticalLine/VerticalLine';
import VideoBackground from '../VideoBackground/VideoBackground';

interface NeurotypeSurveyProps {
  onNavigate: (componentName: ComponentKey) => void;
}

const NeurotypeSurvey: React.FC<NeurotypeSurveyProps> = ({ onNavigate }) => {
  const { state, dispatch, saveSurveyResponse } = useSurvey();
  const hasInitialized = useRef(false);

  function isChoice(obj: any): obj is Choice {
    return (
      obj &&
      (typeof obj.value === 'string' || typeof obj.value === 'number') &&
      typeof obj.text === 'string'
    );
  }

  const neurotypes: Neurotype[] = neurotypeData.components;

  interface QuestionDatum {
    id: string;
    type: 'SINGLE_SELECT' | 'MULTI_SELECT';
    prompt: string;
    promptHelperText?: string;
    choices: Choice[];
  }

  const questionData: QuestionDatum[] = [
    {
      id: 'behavior-prefs-question-1',
      type: 'MULTI_SELECT',
      prompt: 'Which of the following do you prefer?',
      promptHelperText: 'Choose two.',
      choices: [],
    },
    {
      id: 'behavior-prefs-question-2',
      type: 'SINGLE_SELECT',
      prompt: 'Which is more you?',
      choices: [],
    },
    {
      id: 'behavior-prefs-question-3',
      type: 'SINGLE_SELECT',
      prompt: 'Which do you prefer?',
      choices: [],
    },
    {
      id: 'behavior-prefs-question-4',
      type: 'SINGLE_SELECT',
      prompt: 'Which is more you?',
      choices: [],
    },
  ];

  const [questions, setQuestions] = useState<QuestionDatum[]>(questionData);
  const [error, setError] = useState<string>('');

  const currentQuestionIndex = state.surveyResponse.currentQuestionIndex;
  const answers = state.surveyResponse.answers;

  const isFirstQuestion = currentQuestionIndex === 0;

  const questionCount = () => 21;

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      dispatch({
        type: 'SET_CURRENT_QUESTION_INDEX',
        payload: currentQuestionIndex - 1,
      });
    }
  };

  const handleAnswerChange = (answer: Answer) => {
    dispatch({
      type: 'UPDATE_ANSWER',
      payload: answer,
    });
  };

  const isButtonEnabled = useMemo(() => {
    if (currentQuestionIndex === 0) {
      const currentAnswers = answers.find(
        (a) => a.questionId === questions[0].id,
      );
      return (
        currentAnswers &&
        Array.isArray(currentAnswers.value) &&
        currentAnswers.value.length === 2
      );
    } else {
      const currentAnswer = answers[currentQuestionIndex];
      return currentAnswer?.value;
    }
  }, [answers, currentQuestionIndex, questions]);

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleContinue();
  };

  const handleContinue = async () => {
    const currentAnswer = answers.find(
      (ans) => ans.questionId === questions[currentQuestionIndex].id,
    );
    const currentAnswers = currentAnswer
      ? Array.isArray(currentAnswer.value)
        ? currentAnswer.value
        : [currentAnswer.value]
      : [];
    if (currentQuestionIndex === 0 && currentAnswers.length !== 2) {
      setError('You must select exactly 2 options.');
      return;
    }
    setError('');

    const isLastQuestion = currentQuestionIndex === questions.length - 1;

    if (isLastQuestion) {
      saveSurveyResponse();
      onNavigate('survey' as ComponentKey);
    } else {
      dispatch({
        type: 'SET_CURRENT_QUESTION_INDEX',
        payload: currentQuestionIndex + 1,
      });
    }
  };

  const initializeQuestions = useCallback(() => {
    if (currentQuestionIndex === 0 && !hasInitialized.current) {
      let updatedQuestions = [...questions];
      updatedQuestions[0].choices = neurotypes.map((nt) => ({
        value: nt.question1.value,
        text: nt.question1.text,
      }));
      setQuestions(updatedQuestions);
      hasInitialized.current = true;
    } else if (currentQuestionIndex > 0) {
      const neurotypeCandidates = Array.isArray(answers[0]?.value)
        ? answers[0].value
        : [answers[0]?.value];

      if (
        neurotypeCandidates &&
        Array.isArray(neurotypeCandidates) &&
        neurotypeCandidates.length === 2
      ) {
        let updatedQuestions = [...questions];
        updatedQuestions[currentQuestionIndex].choices =
          neurotypeCandidates.map((val: string | number) => {
            const index = parseInt(val.toString()) - 1;
            const questionKey =
              `question${currentQuestionIndex + 1}` as keyof Neurotype;
            const question = neurotypes[index][questionKey];
            return isChoice(question)
              ? { value: question.value, text: question.text }
              : { value: '', text: 'Invalid data' };
          });
        setQuestions(updatedQuestions);
      }
    }
  }, [currentQuestionIndex, answers, neurotypes, questions]);

  useEffect(() => {
    initializeQuestions();
  }, [currentQuestionIndex, answers, initializeQuestions]);

  const currentQuestion = questions[currentQuestionIndex] || {
    id: '',
    choices: [],
    prompt: '',
    type: 'MULTI_SELECT',
  };

  return (
    <div className='relative min-h-full'>
      {/* Main video background */}
      <VideoBackground />

      {/* Content */}
      <div className='survey-transition-item'>
        <div
          id='neurotype-survey'
          className='flex flex-col justify-start lg:justify-center lg:items-center w-full h-full'
          style={{ minHeight: 'calc(100vh - 75px)' }}
        >
          <div className='lg:outline lg:outline-white rounded-lg px-12 lg:px-5 pt-8 lg:pt-6 lg:pb-16 w-full lg:w-full lg:max-w-[61.875rem] relative'>
            <div className='flex flex-row'>
              <div className='flex-1'>
                <div className='flex w-full lg:hidden'>
                  <Header
                    progressInfo={{
                      totalQuestionsCount: questionCount(),
                      currentQuestionIndex: currentQuestionIndex,
                    }}
                  />
                </div>

                <div className='hidden lg:flex'>
                  <Title horizontal={true} />
                </div>
              </div>

              <div className='flex-1 hidden lg:flex lg:justify-end'>
                <div className='pt-3 pr-9'>
                  <ProgressTracker
                    totalQuestionsCount={questionCount()}
                    currentQuestionIndex={currentQuestionIndex}
                  />
                </div>
              </div>
            </div>

            <VerticalLine />

            <div className='lg:flex lg:flex-col lg:justify-center lg:align-center lg:px-10'>
              <form onSubmit={handleFormSubmit}>
                <Question
                  question={{
                    id: currentQuestion.id,
                    label: currentQuestion.prompt,
                    sublabel: currentQuestion.promptHelperText,
                    choices: currentQuestion.choices,
                    questionType: currentQuestion.type,
                  }}
                  currentIndex={currentQuestionIndex}
                  totalQuestions={questions.length}
                  answers={answers}
                  onChange={handleAnswerChange}
                  error={error}
                />

                <div className='grid grid-cols-1 lg:grid-cols-2'>
                  <div></div>
                  <div className='flex flex-col justify-start lg:justify-center items-center m-12 lg:mt-0'>
                    <div className='lg:flex lg:flex-col lg:justify-center lg:align-center lg:pl-10'>
                      <button type='submit' disabled={!isButtonEnabled}>
                        Continue
                      </button>

                      {!isFirstQuestion && (
                        <div
                          className='text-center underline text-sm mt-2.5 cursor-pointer'
                          onClick={handleBack}
                        >
                          Back
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NeurotypeSurvey;
