import React from 'react';
import Header from '../Header/Header';
// import neurotypeData from '../../data/neurotypes.json';
import questionnaireData from '../../data/questionnaire.json';
import Title from '../Title/Title';
import { ComponentKey } from '../../types/component-key';
import ProgressTracker from '../ProgressTracker/ProgressTracker';
import './Intro.scss';

interface IntroProps {
  onNavigate: (componentName: ComponentKey) => void;
}

const Intro: React.FC<IntroProps> = ({ onNavigate }) => {
  const questionCount = () => {
    return (
      4 + questionnaireData.components.length
    );
  };

  return (
    <div
      id='intro'
      className='flex flex-col justify-start lg:justify-center lg:items-center w-full h-full'
      style={{ minHeight: 'calc(100vh - 75px)' }}
    >
      <div className='lg:outline lg:outline-white rounded-lg px-0 lg:px-5 lg:pt-6 lg:pb-16 w-full lg:w-full lg:max-w-[61.875rem] relative'>
        <div className='flex flex-row'>
          <div className='flex-1'>
            <div className='flex flex-col w-full pt-8 lg:hidden'>
              <Header
                progressInfo={{
                  totalQuestionsCount: questionCount(),
                  currentQuestionIndex: 0,
                }}
              />

              <div className='flex justify-end align-end pr-6 mb-12 w-full'>
                <div className='flex justify-center align-center bg-purple rounded-lg text-white p-3 tooltip text-center'>
                  <div className='font-bold text-sm'>
                    Track your progress here.
                  </div>
                  <div className='text-xs'>
                    It's only {questionCount()} questions, no cap!
                  </div>
                </div>
              </div>
            </div>

            <div className='hidden lg:flex'>
              <Title horizontal={true} />
            </div>
          </div>

          <div className='flex-1 hidden lg:flex flex-col lg:justify-end lg:align-end lg:w-full'>
            <div className='pt-3 pr-9 flex flex-col justify-end'>
              <div className='flex justify-end'>
                <ProgressTracker
                  totalQuestionsCount={questionCount()}
                  currentQuestionIndex={0}
                />
              </div>

              <div className='flex justify-end align-end pr-10 mt-10 mb-12 w-full'>
                <div className=' bg-purple text-white pt-3 pb-5 lg:pb-3 px-4 tooltip text-center chunky-radius'>
                  <div className='font-bold text-sm lg:font-base'>
                    Track your progress here.
                  </div>

                  <div className='text-xs lg:text-sm'>
                    It's only {questionCount()} questions, no cap!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex flex-col justify-center items-center w-full h-full lg:mt-24 px-12 lg:px-0'>
          <div className='flex flex-col items-center justify-center'>
            <div className='text-2xl text-center uppercase'>
              <h1>Let's get started</h1>
            </div>
          </div>

          <div className='lg:flex lg:flex-col lg:justify-center lg:align-center'>
            <div className='bg-white max-w-[29.313rem] mx-auto rounded-lg mt-3 blurred px-5 py-[1.563rem] text-base'>
              <p className='text-center place-content-center leading-5'>
                Take this <strong> {questionCount()} question quiz </strong> to discover your personalized Neurotype.
              </p>

              <p className='text-center place-content-center p-4 lg:pb-2 leading-5'>
              Tap the button below to begin.
              </p>

              <div className='grid place-content-center pb-0'>
                <img src='/images/icons/chevron-down.svg' alt='chevron' />
              </div>
            </div>

            <div className='flex flex-col items-center mt-10 lg:mt-28 lg:mb-[62px]'>
              <button
                className='mt-6 lg:mb:0'
                onClick={() => onNavigate('neurotypeSurvey' as ComponentKey)}
              >
                continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
