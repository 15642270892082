import React from 'react';
import VideoBackground from '../VideoBackground/VideoBackground';

interface LayoutContainerProps {
  children: React.ReactNode;
}

const LayoutContainer: React.FC<LayoutContainerProps> = ({ children }) => {
  const currentYear = new Date().getFullYear();

  return (
    <div className='flex flex-col min-h-full'>
      <div className='flex flex-col bg-cover bg-center bg-no-repeat flex-grow relative'>
        {/* Main video background */}
        <VideoBackground />

        {/* Content */}
        <div className='flex-grow overflow-auto relative z-10'>
          {children}

          <footer
            className='flex flex-col items-center justify-between mt-10 py-3 w-full px-4 flex-shrink-0'
            style={{
              background: 'linear-gradient(180deg, #c1bfcd 0%, #fff 12.71%)',
            }}
          >
            <div className='flex justify-center items-end'>
              <div className='h-full max-h-6 flex items-end'>
                <img
                  src='/images/NI-logo.svg'
                  alt='Not Impossible Labs Logo'
                  className='h-6'
                />
              </div>
              <div className='h-full max-h-6 flex items-end pl-7'>
                <img
                  src='/images/LH-logo.svg'
                  alt='Linus Health Logo'
                  className='h-5'
                />
              </div>
            </div>
            <div className='flex f-width text-center font-normal text-xs pt-2'>
              <p>
                Copyright {currentYear} Not Impossible Labs - Powered by Linus
                Health
              </p>
            </div>
            <div className='flex-1'></div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default LayoutContainer;
