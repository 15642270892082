import React from 'react';
import ProgressTracker from '../ProgressTracker/ProgressTracker';
import './Header.scss';

export interface HeaderProps {
  progressInfo?: {
    totalQuestionsCount: number;
    currentQuestionIndex: number;
  };
  showTitleArea?: boolean;
  showExplainerText?: boolean;
  marginBottom?: string;
}

const Header: React.FC<HeaderProps> = ({
  progressInfo = {
    totalQuestionsCount: 1,
    currentQuestionIndex: 0,
  },
  showExplainerText = false,
  marginBottom = 'mb-3.5',
}) => {
  return (
    <div className='w-full'>
      <div className={`${marginBottom} flex justify-center items-start`}>
        <div className='flex-1'></div>

        <div className='flex justify-center flex-grow-0'>
          <img src='/images/brain.svg' alt='brain' className='brain w-[92px]' />
        </div>

        <div className='flex-1 flex items-start'>
          {progressInfo?.totalQuestionsCount > 1 &&
            progressInfo?.currentQuestionIndex !== undefined && (
              <div className='ml-2'>
                <ProgressTracker
                  totalQuestionsCount={progressInfo.totalQuestionsCount}
                  currentQuestionIndex={progressInfo.currentQuestionIndex}
                />
              </div>
            )}
        </div>
      </div>
      {showExplainerText && (
        <div className='flex justify-end align-end pr-6 mb-12 w-full'>
          <div className='bg-purple rounded-lg text-white p-3 tooltip text-center'>
            <div className='font-bold text-sm leading-normal'>
              Track your progress here.
            </div>
            <div className='text-xs'>
              It's only {progressInfo.totalQuestionsCount} questions, no cap!
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
