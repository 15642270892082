import React from 'react';
import './ConsentPolicy.scss';

interface ConsentPolicyProps {
  onClose: () => void;
}

const ConsentPolicy: React.FC<ConsentPolicyProps> = ({ onClose }) => {
  return (
    <div id='consent-policy' className='flex flex-col h-full relative'>
      <button
        onClick={onClose}
        className='fixed lg:absolute top-[24px] lg:top-0 right-[24px] lg:right-[12px] m-3 p-1 z-10 naked'
        style={{ cursor: 'pointer' }}
        aria-label='Close modal'
      >
        <img
          src='/images/icons/X.svg'
          alt='Close'
          style={{ display: 'block', width: '24px', height: '24px' }}
        />
      </button>
      <div className='flex flex-col flex-grow blurred rounded-lg policy-text p-3 pt-12'>
        <p>
          <strong>
            CONSENT TO TAKE PART IN A CLINICAL RESEARCH STUDY AND ACCOUNTABILITY
            ACT (HIPAA) AUTHORIZATION AGREEMENT PERMISSION TO REVIEW, USE, AND
            RELEASE INFORMATION ABOUT YOU
          </strong>
        </p>
        <p>Sponsor / Study Title: Linus Health & Not Impossible Labs</p>
        <p>Protocol Number: NIL-001</p>
        <p>Principal Investigator: Linda Jeo Zerba</p>
        <p>Telephone: 503-201-4251</p>
        <p>Address: 628 California Ave, Venice, California, 90291</p>

        <p>
          The terms &ldquo;you&rdquo; and &ldquo;your&rdquo; in this form refer
          to the subject and not to the person providing consent for the
          subject.
        </p>
        <p>
          You are being asked to participate in a clinical research study.
          &nbsp;Your participation is entirely voluntary, meaning that you are
          free to say yes or no.&nbsp;
        </p>
        <p>
          This document is called an &ldquo;Informed Consent Form.&rdquo; It
          will explain the purpose of the study, what you can expect if you
          decide to take part, including the risks and possible benefits, and
          how your medical information will be used. The process of explaining
          the study and obtaining your permission to participate in the study is
          known as the informed consent process.
        </p>
        <p>
          Please read this form carefully. &nbsp;If you decide to participate,
          you must select agree at the end of this consent. This will serve as
          the equivalent of a signature storing and timestamping the time and
          date. You cannot take part in this clinical study until you click
          agree. You should agree to this form only when you fully understand
          the details of the study and agree to the commitment.
        </p>
        <p>
          After agreeing to this Informed Consent Form, you are free to change
          your mind―at any time―and leave the study if you wish. &nbsp;If you
          decide not to participate, or if you decide to stop being in the
          study, this will not affect the standard of care you receive, there
          will be no penalty, and you will not lose any benefits to which you
          are otherwise entitled. &nbsp;You will not give up any legal rights by
          signing this form.
        </p>
        <p>
          A description of this clinical trial will be available on{' '}
          <em>http://www.ClinicalTrials.gov</em>, as required by U.S. Law. This
          website will not include information that can identify you. At most,
          the website will include a summary of the results. You can search this
          website at any time.
        </p>
        <p>
          <strong>INTRODUCTION</strong>
        </p>
        <p>
          This clinical study will be conducted by Linus Health, Inc. and Not
          impossible Labs (referred to as the Sponsor in this Informed Consent
          Form). In this clinical study, a questionnaire will be used to infer
          your brain health archetype and information will be provided to inform
          you on how to care for your brain health.&nbsp;
        </p>
        <p>This study is being conducted for research purposes.</p>
        <p>
          The research study in which you are asked to take part has been
          reviewed by the responsible Institutional Review Board (IRB). &nbsp;An
          IRB is a group of scientific and lay people who review any research
          done in humans to protect the welfare, rights, and privacy of the
          subjects in the study.&nbsp;
        </p>
        <p>
          Approximately 200,000 people, Age of 18 and older except in the States
          of Alabama and Nebraska where Age of Majority requires participants to
          be 19 years or older. You must be 21 or older for any participants in
          Puerto Rico.
        </p>
        <p>
          <strong>INFORMATION ABOUT THE STUDY</strong>
        </p>
        <p>
          This is a cross-sectional observational clinical research study. In
          this study, participants will complete the Linus Health&rsquo;s Life
          and Health Questionnaire (LHQ-15) to identify factors related to their
          brain health. This will be accessible via web browser and QR code for
          mobile devices.
        </p>
        <p>
          This study will be carried out in accordance with United States of
          America legislation and internationally recognized guidelines.
          &nbsp;It has been reviewed by an Independent Review Board (IRB) and
          the FDA guidelines.
        </p>
        <p>
          <strong>WHAT WILL HAPPEN DURING THE STUDY?</strong>
        </p>
        <p>
          If you agree to participate in the research study, you will be asked
          to select agree at the end of the Informed Consent Form. &nbsp;By
          signing this form, you are agreeing to follow the instructions
          provided.
        </p>
        <p>Study Procedures:&nbsp;</p>
        <ul>
          <li>
            <strong>Qualifying Questions:&nbsp;</strong>You will be asked
            questions to determine whether you qualify to be in this study,
            based on specific study criteria.
          </li>
          <li>
            <strong>Medical History Review:&nbsp;</strong>Limited medical
            history will be collected. &nbsp;
          </li>
          <li>
            <strong>
              Linus Health Life and Health Questionnaire (LHQ)&nbsp;
            </strong>
          </li>
        </ul>
        <p>
          <strong>SCREENING</strong>
        </p>
        <p>
          Participants can only take the test one time and this will serve as a
          screening visit.
        </p>
        <p>
          <strong>TESTING PERIOD</strong>
        </p>
        <p>
          The testing period described below will take approximately 10 minutes.
          &nbsp;
        </p>
        <p>
          <strong>Visit 1 (Time patient accesses assessment)</strong>
        </p>
        <p>
          <strong>WHAT WILL I BE EXPECTED TO DO WHILE IN THE STUDY?</strong>
        </p>
        <p>
          Please consider the study time commitments and responsibilities as a
          research subject when you are deciding to take part. &nbsp;Your
          responsibilities as a study subject may include the following:
        </p>
        <ul>
          <li>Provide some information on your current medical conditions.</li>
          <li>
            If you decide to stop your participation, you may exit the
            assessment via the web browser.
          </li>
        </ul>
        <p>
          <strong>RISKS OF THE STUDY</strong>
        </p>
        <p>
          The risk of harm posed by participating in this study is minimal.
          &nbsp;The main risk to subjects is a potential breach of
          confidentiality. &nbsp;Identifiers will not be recorded in the
          study&rsquo;s database. &nbsp;Instead, each subject will be assigned a
          study identification number that will be used to identify each subject
          in the study&rsquo;s database. They will also be assured that no
          publication or communication with anyone outside the study staff and
          appropriate regulatory agencies will involve any personally
          identifiable data that can be linked or traced back to them.
        </p>
        <p>
          As part of this research, you may be required to use one or more of
          the following: a phone or web app/site, or a device that gathers
          information about you. While using these, information about you may be
          collected and shared with the researchers or people outside of the
          study. &nbsp;This data might include personal information, health
          information, location, web browsing history, or social media use. A
          complete description of the data collection and sharing for an app, or
          device can commonly be found in the Terms of Use, End User License
          Agreement, or Privacy Policy associated with the device. If you would
          like to a copy of this document for your own records, you can click
          &ldquo;Download This Document&rdquo;, located at the top right of the
          screen. &nbsp;&nbsp;
        </p>
        <p>
          While the Terms of Use, End User License Agreement, or Privacy Policy
          may include statements limiting your rights if you are harmed as a
          result of your use of the app, or device in this study, you do not
          release the investigator, sponsor, institution, or agents from
          responsibilities for mistakes. &nbsp;You also do not waive any of your
          rights as a research subject.
        </p>
        <p>
          <strong>UNKNOWN RISKS</strong>
        </p>
        <p>
          There may be risks to you that are currently not known or cannot be
          predicted.
        </p>
        <p>
          <strong>ARE THERE ANY BENEFITS TO PARTICIPATING IN THE STUDY?</strong>
        </p>
        <p>
          While there will be no direct benefit to participants in the study,
          &nbsp;A potential benefit of this research for participants is
          becoming familiar with lifestyle and psychosocial factors important
          for their brain health. Information learned from this study may also
          help others in the future.&nbsp;
        </p>
        <p>
          <strong>WILL IT COST ME ANYTHING TO BE IN THE STUDY?</strong>
        </p>
        <p>The study will be conducted at no cost to you.&nbsp;</p>
        <p>
          <strong>WILL I BE PAID FOR BEING IN THE STUDY?</strong>
        </p>
        <p>There is no compensation for study participants.&nbsp;</p>
        <p>
          <strong>DO STUDY STAFF GET PAID FOR CONDUCTING THE STUDY?</strong>
        </p>
        <p>
          Not Impossible Labs, Inc., the Sponsor of the study, is paying the
          study staff for their work in this study.
        </p>
        <p>
          <strong>ALTERNATIVES TO PARTICIPATION&nbsp;</strong>
        </p>
        <p>
          This research study is for research and development purposes. The
          alternative is to not participate in this study.
        </p>
        <p>
          <strong>WILL MY HEALTH INFORMATION BE KEPT PRIVATE?</strong>
        </p>
        <p>
          Records of your participation in this study will be kept confidential
          during the study and for the retention period described below except
          if disclosure is required by law or as described in this Informed
          Consent Form. &nbsp;Your personal health information needs to be
          shared for research and other reasons. &nbsp;The study staff, Sponsor,
          or persons working on behalf of the Sponsor and, under certain
          circumstances, Regulatory Authorities in your country, such as the
          FDA, or similar agencies in other countries, will be able to inspect
          and copy confidential study-related records that identify you by name.
          &nbsp;Therefore, complete privacy of your personal health information,
          cannot be promised. &nbsp;However, sharing your personal health
          information will be guided by professional standards of
          confidentiality and the law.
        </p>
        <p>
          <strong>WHAT PERSONAL DATA WILL BE USED AND DISCLOSED?</strong>
        </p>
        <p>
          Your name will not appear on the questionnaire. You will be assigned a
          subject identification number. &nbsp;The questionnaire will ask you
          your first and last name, year of birth, race, education, health
          behaviors, and general health status. This type of information may
          also be shared with others, as described below. Your medical records
          may include other health information about you and may include
          information that directly identifies you. &nbsp;Authorized Recipients
          from the groups identified below may need to look at your medical
          records to make sure that the information on the study forms is
          correct, that the study was conducted properly, or for health and
          safety reasons. &nbsp;These types of reviews will take place at the
          study center where the medical records are stored or by providing
          restricted access to electronic medical records and can take place
          after the study is over.
        </p>
        <p>
          <strong>
            WHO WILL USE AND DISCLOSE YOUR PERSONAL HEALTH INFORMATION?
          </strong>
        </p>
        <p>
          The study staff may also use your personal health information to
          prepare reports or publications about the study. &nbsp;However, your
          name will not appear in any report or publication. &nbsp;The Sponsor
          and/or its designees, as well as the study staff, may disclose your
          personal health information to Authorized Recipients as discussed in
          the next section. &nbsp;Sponsor, its designees, study staff, and
          Authorized Recipients are bound by obligations of confidentiality.
        </p>
        <p>
          Who will receive your Personal Data? <strong>&nbsp;</strong>Your
          personal health information may be shared with the following people or
          groups (&ldquo;Authorized Recipients&rdquo;)
        </p>
        <ul>
          <li>Representatives of Sponsor</li>
          <li>
            Representatives of the Institutional Review Board (an Institutional
            Review Board that reviews this study)
          </li>
          <li>
            Regulatory authorities, such as the Food and Drug Administration
            (FDA), or other US governmental agencies
          </li>
          <li>Other authorized users</li>
        </ul>
        <p>
          There is a risk of loss of confidentiality in research studies. Every
          effort will be made to protect you and your health information to the
          extent possible.
        </p>
        <p>Consent to use and disclose personal health information:</p>
        <p>
          If you sign the accompanying consent form, you are giving permission
          for the use and disclosure of your personal health information for the
          purposes of this research Study. &nbsp;You do not have to give this
          permission. &nbsp;However, if you do not, you will not be able to
          participate in the Study.
        </p>
        <p>
          <strong>WILL YOUR CONSENT EVER EXPIRE?</strong>
        </p>
        <p>This consent does not have an expiration date.&nbsp;</p>
        <p>
          <strong>MAY YOU TAKE BACK YOUR CONSENT?</strong>
        </p>
        <p>
          You have the right to revoke (take back) your consent, at any time, by
          notifying the study staff with a phone call or sending a written
          notice to the study staff.
        </p>
        <p>
          If you revoke your consent, you can no longer continue to participate
          in this study and the study staff will not collect any new personal
          health information about you; however, they can continue to use and
          disclose any already collected information necessary for the
          reliability of the study.
        </p>
        <p>
          <strong>WHAT HAPPENS IF YOU LEAVE THE STUDY EARLY?</strong>
        </p>
        <p>
          If you stop participating in the study early, for any reason, there
          will be an optional box to capture any comments as to why.&nbsp;
        </p>
        <p>
          <strong>WHOM TO CONTACT ABOUT THIS STUDY</strong>
        </p>
        <p>
          During the study, if you experience any medical problems, suffer a
          research-related injury, or have questions, concerns, or complaints
          about the study such as:
        </p>
        <ul>
          <li>
            Whom to contact in the case of a research-related injury or illness;
          </li>
          <li>Payment or compensation for being in the study, if any;</li>
          <li>Your responsibilities as a research subject;</li>
          <li>Eligibility to participate in the study;</li>
          <li>
            The Investigator&rsquo;s or study site&rsquo;s decision to withdraw
            you from participation;
          </li>
          <li>Results of tests and/or procedures;</li>
        </ul>
        <p>
          <strong>
            Please contact the Investigator at the telephone number listed on
            the first page of this consent document
          </strong>
          <strong>.</strong>&nbsp;
        </p>
        <p>
          &quot;This study has been reviewed by an institutional review board
          (IRB), which is a committee that has reviewed this research study to
          help ensure that your rights and welfare as a research participant are
          protected and that the research study is carried out in an ethical
          manner.&quot;
        </p>
        <p>
          <strong>IS BEING IN THE STUDY COMPLETELY VOLUNTARY?</strong>
        </p>
        <p>Entering a research study is completely voluntary.</p>
        <ul>
          <li>You may always say &ldquo;no.&rdquo;</li>
          <li>You do not have to take part in the study.</li>
          <li>
            If you start a study, you may stop at any time. &nbsp;You do not
            need to give a reason.
          </li>
          <li>
            If you do not want to be in the study, or you want to stop being in
            the study at a later time, you will not be penalized or lose any
            benefits.
          </li>
          <li>
            If you stop, you should inform the study staff and follow the
            instructions they give you.
          </li>
        </ul>
        <p>
          Your participation in the research may stop at any time for any
          reason, such as:
        </p>
        <ul>
          <li>
            The Sponsor, the FDA, or the study staff decide to stop the study.
          </li>
          <li>
            The Sponsor or the study staff decides to stop your part in the
            study for your safety.
          </li>
          <li>You do not follow the study rules.</li>
          <li>You have a new injury or illness.</li>
          <li>You decide to stop.</li>
        </ul>
        <p>
          <strong>
            WHAT IF NEW INFORMATION BECOMES AVAILABLE WHILE I&rsquo;M IN THE
            STUDY?
          </strong>
        </p>
        <p>
          You will be told in a timely manner about any new information found
          during the study via email collected.&nbsp;
        </p>
        <p>
          <strong>STATEMENT OF CONSENT</strong>
        </p>
        <p>
          I have read this Informed Consent Form and its contents were explained
          to me. &nbsp;All my questions were answered to my satisfaction.
          &nbsp;I agree to be in this clinical study for the purposes listed
          above. &nbsp;I understand that my participation in this study is
          voluntary and that I am free to withdraw at any time and without
          giving any reason. &nbsp; I am not giving up any of my legal rights by
          signing this form. By clicking agree below, I am giving my consent and
          this will serve as the equivalent of a signature storing and
          timestamping the time and date.&nbsp;
        </p>
        <p>
          <strong>
            Health Insurance Portability and Accountability Act (HIPAA)
            Authorization Agreement Permission to Review, Use, and Release
            Information About You
          </strong>
        </p>
        <p>
          If you decide to be in this study, the investigator and study staff
          will use and share personal health information about you to conduct
          the study. &nbsp;Personal health information may include:
        </p>
        <ul>
          <li>First and Last Name</li>
          <li>Email address&nbsp;</li>
          <li>Sex</li>
          <li>Your date of birth</li>
          <li>Race/Ethnicity</li>
          <li>Your medical history</li>
          <li>State&nbsp;</li>
          <li>Zip Code</li>
        </ul>
        <p>
          Except when required by law, you will not be identified by name,
          address, telephone number or other facts that could identify the
          health information as yours. Personal health information may come from
          your study records or from existing records kept by your doctor or
          other health care workers.
        </p>
        <p>
          For this study, the study staff may share personal health information
          about you with authorized users. &nbsp;Authorized users may include:
        </p>
        <ul>
          <li>Representatives of Sponsor</li>
          <li>
            Representatives of the IRB (an Institutional Review Board that
            reviews this study)
          </li>
          <li>
            Regulatory authorities, such as the FDA, other US governmental
            agencies
          </li>
          <li>Other authorized users</li>
        </ul>
        <p>
          <strong>
            WHY WILL THIS INFORMATION BE USED AND/OR GIVEN TO OTHERS?
          </strong>
        </p>
        <p>
          The sponsor and the groups above will use your health information:
        </p>
        <ul>
          <li>To complete this research</li>
          <li>To evaluate the results of the study</li>
          <li>To check that the study is being done properly</li>
          <li>
            To obtain marketing approval for new products resulting from this
            research
          </li>
        </ul>
        <p>
          Once your personal health information has been shared with authorized
          users, they may no longer be protected by Federal privacy law.
        </p>
        <p>
          Researchers intend to publish the research (for example, in scholarly
          publications), or make public presentations based on the research.
          &nbsp;If the results of the study are published, your identity will
          remain confidential.
        </p>
        <p>
          You may take back your permission to use and share personal health
          information about you at any time by writing to the investigator using
          the information on the first page of this document. &nbsp;If you do
          this, you will not be able to stay in this study. &nbsp;No new
          personal health information that identifies you will be gathered after
          your written request is received, unless you have a side effect
          related to the study. &nbsp;However, personal health information about
          you that has already been gathered may still be used and given to
          others as described in this form.
        </p>
        <p>
          If you withdraw from the study but do not withdraw your Authorization,
          new health information may be collected until this study ends. &nbsp;
        </p>
        <p>
          If you do not withdraw this Authorization, it will remain in effect
          indefinitely.
        </p>
        <p>
          If the research site is located in California, Delaware, Indiana,
          Washington, or Wisconsin this authorization will expire on 31Dec2060.
        </p>
        <p>
          There is no expiration of this authorization except for research
          conducted in the states listed above.&nbsp;
        </p>
        <p>
          Your right to access your personal health information in the study
          records will be suspended during the study to keep the study results
          from changing. &nbsp;When the study is over, you can access your study
          personal health information. &nbsp;You do not have to sign this form.
          If you decide not to sign this informed consent form, you will not be
          able to take part in the study.&nbsp;
        </p>
        <p>
          Your decision to withdraw your Authorization or not to participate
          will not involve any penalty or loss of access to treatment or other
          benefits to which you are otherwise entitled. &nbsp;
        </p>
        <p>
          <strong>STATEMENT OF AUTHORIZATION</strong>
        </p>
        <p>
          I have read this Informed Consent Form and its contents were
          explained. &nbsp;I voluntarily agree to allow the study staff to
          collect, use, and share my personal health information as specified in
          this form. &nbsp;I understand that my consent to the collection of my
          personal health information is voluntary and that I am free to
          withdraw my consent at any time, without giving a reason; however,
          personal health information about me that has already been gathered
          may still be used and given to others as described. &nbsp;I will
          receive a copy of this form for my records. &nbsp;I am not giving up
          any of my legal rights by signing this form.
        </p>
      </div>

      <div className='flex justify-center mt-8'>
        <a
          href='/not_impossible_labs_study_informed_consent_may_01_2024.pdf'
          download='Consent-Policy.pdf'
        >
          <button type='submit'>Download PDF</button>
        </a>
      </div>
    </div>
  );
};

export default ConsentPolicy;
