import React, { useState } from 'react';
import Modal from '../../Modal/Modal';
import { useUser } from '../../../context/UserContext';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import './BasicUserInfo.scss';
import { User } from '../../../types/user';
import ConsentPolicy from '../../ConsentPolicy/ConsentPolicy';
import Title from '../../Title/Title';
import { ComponentKey } from '../../../types/component-key';
import VerticalLine from '../../VerticalLine/VerticalLine';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  acceptTerms?: boolean;
}

const schema = yup.object({
  firstName: yup.string().required('First Name is a required field'),
  lastName: yup.string().required('Last Name is a required field'),
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is a required field'),
  acceptTerms: yup.boolean().oneOf([true], 'You must accept the terms.'),
});

interface BasicUserInfoProps {
  onNavigate: (componentName: ComponentKey) => void;
}

const BasicUserInfo: React.FC<BasicUserInfoProps> = ({ onNavigate }) => {
  const { user, setUser } = useUser();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const [isModalOpen, setModalOpen] = useState(false);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const updatedUser: User = {
      ...user,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      consent: data.acceptTerms !== undefined ? data.acceptTerms : user.consent,
    };

    setUser(updatedUser);
    onNavigate('demographicDetails' as ComponentKey);
  };

  return (
    <div
      id='basic-user-info'
      className='flex flex-col lg:justify-center lg:items-center w-full h-full'
      style={{ minHeight: 'calc(100vh - 112px)' }}
    >
      <div className='lg:outline lg:outline-white backdrop px-12 lg:px-5 pt-8 lg:pt-6 lg:pb-16 mx-auto lg:max-w-[61.875rem] relative'>
        <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-6'>
          {/* col 1 on md sized devices */}
          <div className='flex flex-col justify-start items-center'>
            <Title horizontal={true} />

            <div className='h-full flex flex-col justify-center'>
              <div className='text-2xl text-center uppercase'>
                <h1>Sign Up & Consent</h1>
              </div>

              <div className='bg-white max-w-80 lg:w-80 mx-auto rounded-lg my-[14px] lg:mt-1 lg:mb-0 blurred px-5 py-5 lg:px-5 lg:py-6'>
                <p className='text-base font-medium text-center lg:text-left  place-content-center leading-5'>
                  Before the quiz starts we will need some details about you.
                  This enables us to better craft and deliver your brain health
                  recommendations.
                </p>
              </div>
            </div>
          </div>

          <VerticalLine />

          {/* col 2 on md sized devices */}
          <div className='lg:flex lg:flex-col lg:justify-center lg:align-center lg:pl-14 lg:pr-24 lg:pt-40'>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='pace-y-4 lg:space-y-6'
            >
              <div className='mb-4'>
                <label
                  htmlFor='firstName'
                  className='block text-xs font-bold ml-3'
                >
                  First Name
                </label>
                <div className='relative'>
                  <input
                    type='text'
                    {...register('firstName')}
                    placeholder='First Name'
                    className='w-full px-3 py-2 border border-purple rounded-full focus:outline-none text-base font-normal lg:font-medium blurred'
                  />
                </div>
              </div>

              <div className='mb-4'>
                <label
                  htmlFor='latstName'
                  className='block text-xs font-bold ml-3'
                >
                  Last Name
                </label>
                <div className='relative'>
                  <input
                    type='text'
                    {...register('lastName')}
                    placeholder='Last Name'
                    className='w-full px-3 py-2 border border-purple rounded-full focus:outline-none text-base font-normal lg:font-medium blurred'
                  />
                </div>
              </div>

              <div className='mb-4 relative text-sm'>
                <label htmlFor='email' className='block text-xs font-bold ml-3'>
                  Email
                </label>

                <input
                  type='email'
                  {...register('email')}
                  placeholder='Email'
                  className='w-full px-3 py-2 min-h-[35px] border border-purple rounded-full text-base'
                />

                {errors.email && (
                  <div className='absolute inset-y-0 right-0 items-center pointer-events-none w-5 h-5 rounded-full bg-white flex justify-center align-center mr-2 mt-[1.588rem]'>
                    <img
                      src='/images/icons/!.svg'
                      alt='exclamation mark'
                      className='h-4 lg:h-3'
                    />
                  </div>
                )}
              </div>

              <div className='rounded-lg p-4 blurred'>
                <label className='custom-checkbox-container'>
                  <input
                    type='checkbox'
                    {...register('acceptTerms')}
                    className='custom-checkbox'
                  />
                  <span className='text-sm font-normal ml-2'>
                    By checking this box and proceeding to the next page, you
                    agree to the terms outlined in our informed consent found{' '}
                    <a
                      href='#/'
                      className='text-purple-600 underline underline-offset-2'
                      onClick={(e) => {
                        e.preventDefault();
                        setModalOpen(true);
                      }}
                    >
                      here
                    </a>
                    .
                  </span>
                </label>
              </div>

              <div className='flex justify-center items-center mt-5 pt-5 lg:mt-0 lg:pt-0'>
                <button type='submit' disabled={!isValid}>
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
          <ConsentPolicy onClose={() => setModalOpen(false)} />
        </Modal>
      )}
    </div>
  );
};

export default BasicUserInfo;
