import React from 'react';
import './ProgressTracker.scss';

export interface ProgressTrackerProps {
  totalQuestionsCount: number;
  currentQuestionIndex: number;
}

const ProgressTracker: React.FC<ProgressTrackerProps> = ({
  totalQuestionsCount,
  currentQuestionIndex,
}) => {
  const answeredQuestionCount = currentQuestionIndex + 1;
  const isComplete = answeredQuestionCount === totalQuestionsCount;

  const radius = 13; // Radius of the circle
  const center = 17; // Center of the SVG

  // Convert progress to radians
  const angle =
    2 * Math.PI * (answeredQuestionCount / totalQuestionsCount) - Math.PI / 2;

  // Calculate end point of the arc
  const x = center + radius * Math.cos(angle);
  const y = center + radius * Math.sin(angle);

  // Determine if the arc should be drawn as a large arc (greater than 180 degrees)
  const largeArcFlag =
    answeredQuestionCount / totalQuestionsCount > 0.5 ? 1 : 0;

  let pathData;

  if (isComplete) {
    pathData = [
      // Move to the starting point directly below the center
      `M${center},${center + radius}`,
      // Draw an arc from the starting point to the top of the circle
      `A${radius},${radius} 0 1 1 ${center},${center - radius}`,
      // Draw another arc from the top back to the starting point, completing the circle
      `A${radius},${radius} 0 1 1 ${center},${center + radius}`,
    ].join(' '); // Creates a complete filled circle
  } else {
    pathData = [
      `M${center},${center}`, // Move to center
      `L${center},${center - radius}`, // Line up to the start of the arc at 12 o'clock
      `A${radius},${radius} 0 ${largeArcFlag} 1 ${x},${y}`, // Arc to the calculated point
      'L',
      `${center},${center}`, // Line back to the center
    ].join(' '); // Path for the sector
  }

  return (
    <div className='flex flex-col'>
      <div>
        <svg
          width='34'
          height='34'
          viewBox='0 0 34 34'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle
            id='Ellipse 4'
            cx='17'
            cy='17'
            r='17'
            className='trackerBackground'
          />
          <path
            id='Rectangle 93'
            d='M0 17.4146L16.5854 34H0V17.4146Z'
            className='trackerBackground'
          />
          <circle
            id='Ellipse 5'
            cx='17'
            cy='17'
            r='13'
            className='progressBackground'
          />
          <path
            d={pathData}
            className='progressPath'
            transform='rotate(180, 17, 17)' // Rotate the path by 180 degrees around the center (17, 17)
          />
        </svg>
      </div>

      <div className='text-xs font-semibold'>
        {currentQuestionIndex}/{totalQuestionsCount}
      </div>
    </div>
  );
};

export default ProgressTracker;
