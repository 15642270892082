import React, { useState } from 'react';
import { User } from '../../../types/user';
import { useUser } from '../../../context/UserContext';
import { ComponentKey } from '../../../types/component-key';
import Title from '../../Title/Title';

interface AgeGateProps {
  onNavigate: (componentName: ComponentKey) => void;
}

const AgeGate: React.FC<AgeGateProps> = ({ onNavigate }) => {
  const { user, setUser, triggerSave } = useUser();
  const [ageConfirmation, setAgeConfirmation] = useState<string | null>(null);
  const [lastClicked, setLastClicked] = useState<string | null>(null);

  const handleAnswer = (answer: string) => {
    setAgeConfirmation(answer);
    setLastClicked(answer);
  };

  const buttonClass = (answer: string) => {
    const baseClass = 'normal-case';
    const activeClass =
      lastClicked === answer ? '' : 'unselected blurred blue-text';
    const spacingClass = lastClicked === 'yes' ? 'mr-3' : 'mr-3';

    return `${baseClass} ${activeClass} ${answer === 'yes' ? spacingClass : ''}`;
  };
  const handleContinue = () => {
    if (ageConfirmation !== null) {
      const isMinor = ageConfirmation === 'no';
      const updatedUser: User = {
        ...user,
        minor: isMinor,
      };

      setUser(updatedUser);

      if (isMinor) {
        triggerSave();
        onNavigate('surveyIntro');
      } else {
        onNavigate('basicUserInfo');
      }
    }
  };

  return (
    <div
      id='age-gate'
      className='flex flex-col justify-center items-center w-full h-full'
      style={{ minHeight: 'calc(100vh - 75px)' }}
    >
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='lg:outline lg:outline-white lg:rounded-lg p-12 lg:px-[6.344rem] lg:pt-28 lg:pb-36 lg:w-full lg:max-w-[36.125rem]'>
          <Title showTitleText={true} />
          <div className='max-w-xl lg:max-w-lg mx-auto rounded-lg px-5 py-6 mt-6 lg:mt-16 blurred'>
            <div className='text-lg text-center font-bold pb-1'>
              Are you 18 or older?
            </div>

            <p className='text-center place-content-center leading-5'>
              Or 19 and reside in Alabama or Nebraska, or 21 and reside in
              Puerto Rico?
            </p>
          </div>

          <div className='flex justify-center align-center mt-2.5 lg:mt-5'>
            <button
              className={buttonClass('yes')}
              onClick={() => handleAnswer('yes')}
            >
              Yes
            </button>

            <button
              className={buttonClass('no')}
              onClick={() => handleAnswer('no')}
            >
              No
            </button>
          </div>

          <div className='flex justify-center mt-16 lg:mt-28'>
            <button onClick={handleContinue} disabled={!ageConfirmation}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgeGate;
