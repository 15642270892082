import React from 'react';
import RadioInput from '../form-elements/RadioInput/RadioInput';
import CheckboxInput from '../form-elements/CheckboxInput/CheckboxInput';
import Select from '../form-elements/Select/Select';
import { Answer } from '../../types/answer';
import { Choice } from '../../types/choice';
import './Question.scss';

export interface QuestionProps {
  question: {
    id: string;
    questionType:
      | 'SINGLE_SELECT'
      | 'MULTI_SELECT'
      | 'SINGLE_DROPDOWN_SELECT'
      | 'MULTI_DROPDOWN_SELECT';
    label: string;
    sublabel?: string;
    choices: Choice[];
  };
  currentIndex: number;
  totalQuestions: number;
  onChange: (answer: Answer) => void;
  answers: Answer[];
  error?: string;
  bigLabel?: boolean;
  isSurveyQuestion?: boolean;
}

const NONE_OF_THE_ABOVE = 'None of the above';

const Question: React.FC<QuestionProps> = ({
  question,
  error,
  currentIndex,
  totalQuestions,
  onChange,
  answers,
  bigLabel = true,
  isSurveyQuestion = true,
}) => {
  const handleChangeForRadioInput = (value: Answer) => {
    onChange(value);
  };

  const handleChangeForCheckboxInput = (answer: Answer) => {
    let currentAnswers = answers
      .filter((a) => a.questionId === question.id)
      .flatMap((a) => (Array.isArray(a.value) ? a.value : [a.value]));

    const answerSet = new Set<string | number>(currentAnswers);
    const incomingValues = Array.isArray(answer.value)
      ? answer.value
      : [answer.value];

    const choiceI18nKeys = question.choices.reduce(
      (acc, choice) => {
        if (choice.value !== undefined && choice.text !== undefined) {
          acc[choice.value] = choice.text;
        }
        return acc;
      },
      {} as Record<string | number, string>,
    );

    const isNoneOfTheAbove = incomingValues.some(
      (value) => choiceI18nKeys[value] === NONE_OF_THE_ABOVE,
    );

    if (isNoneOfTheAbove) {
      // If "none-of-the-above" is selected, clear other selections
      const noneOfTheAboveValue = incomingValues.find(
        (value) => choiceI18nKeys[value] === NONE_OF_THE_ABOVE,
      );
      answerSet.clear();
      if (noneOfTheAboveValue !== undefined) {
        answerSet.add(noneOfTheAboveValue);
      }
    } else {
      // Otherwise, update the answer set as usual
      incomingValues.forEach((value) => {
        const stringValue = value.toString();
        if (stringValue.startsWith('-')) {
          const absValue = stringValue.slice(1);
          answerSet.delete(absValue);
        } else {
          answerSet.add(stringValue);
        }
      });

      // If any other option is selected, remove "none-of-the-above"
      Object.entries(choiceI18nKeys).forEach(([key, value]) => {
        if (value === NONE_OF_THE_ABOVE) {
          answerSet.delete(key);
        }
      });
    }

    const newArrayValue = Array.from(answerSet);
    onChange({
      questionId: question.id,
      type: 'ANSWERED',
      value: newArrayValue as string[] | number[],
    });
  };

  const handleChangeForSelect = (value: Answer) => {
    onChange(value);
  };

  const isRadioChecked = (choiceValue: string | number) => {
    return answers.some(
      (ans) => ans.questionId === question.id && ans.value === choiceValue,
    );
  };

  const isCheckboxChecked = (choiceValue: string | number) => {
    const answer = answers.find((ans) => ans.questionId === question.id);

    if (!answer) return false;

    // Ensure answer.value is always treated as an array
    const answerValues = Array.isArray(answer.value)
      ? answer.value
      : [answer.value];

    return answerValues.includes(choiceValue);
  };

  const getSelectedValues = (questionId: string): string[] | number[] => {
    const answer = answers.find((ans) => ans.questionId === questionId);
    if (answer && Array.isArray(answer.value)) {
      // Safely assume now that answer.value is an array
      // Determine the content type of the array elements
      if (typeof answer.value[0] === 'string') {
        return answer.value as string[];
      } else if (typeof answer.value[0] === 'number') {
        return answer.value as number[];
      }
    } else if (typeof answer?.value === 'string') {
      return [answer.value];
    } else if (typeof answer?.value === 'number') {
      return [answer.value];
    }
    return [];
  };

  const renderInputComponent = () => {
    switch (question.questionType) {
      case 'SINGLE_SELECT':
        return question.choices.map((choice) => (
          <RadioInput
            key={choice.value.toString()}
            name={question.id}
            value={choice.value}
            checked={isRadioChecked(choice.value)}
            onChange={handleChangeForRadioInput}
            text={choice.text}
          />
        ));
      case 'MULTI_SELECT':
        return question.choices.map((choice) => (
          <CheckboxInput
            key={choice.value.toString()}
            name={question.id}
            value={choice.value.toString()}
            checked={isCheckboxChecked(choice.value)}
            onChange={handleChangeForCheckboxInput}
            text={choice.text}
          />
        ));
      case 'SINGLE_DROPDOWN_SELECT':
        return (
          <Select
            options={question.choices}
            onChange={handleChangeForSelect}
            selectedValue={getSelectedValues(question.id)}
            multiple={false}
            name={question.id}
            placeholder={question.label}
          />
        );
      case 'MULTI_DROPDOWN_SELECT':
        return (
          <Select
            options={question.choices}
            onChange={handleChangeForSelect}
            selectedValue={getSelectedValues(question.id)}
            multiple={true}
            name={question.id}
          />
        );
      default:
        return <p>No input type available for this question</p>;
    }
  };

  return (
    <div
      className={`${isSurveyQuestion ? 'grid grid-cols-1 lg:grid-cols-2 lg:gap-6 lg:min-h-[32.563rem]' : ''}`}
    >
      {/* col 1 on md sized devices */}
      <div className='flex flex-col justify-start lg:justify-center items-start lg:pr-14'>
        {bigLabel ? (
          <>
            <div className='blurred rounded-lg flex w-full p-3 '>
              <div>
                <img
                  src='/images/icons/Q.svg'
                  alt='Question'
                  className='mr-3 question-icon'
                />
              </div>
              <div className='flex flex-col'>
                <div className='font-medium pt-1'>
                  <label>{question.label}</label>
                </div>
              </div>
            </div>

            <div className='lg:flex lg:justify-end w-full lg:pr-5'>
              <div className='blue-text ms-4 my-2 lg:mb-5'>
                {question.questionType === 'MULTI_SELECT' &&
                !question.sublabel ? (
                  <label className='font-semibold'>
                    Select all that apply:
                  </label>
                ) : question.sublabel ? (
                  <label className='font-semibold'>{question.sublabel}</label>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <>
            <label className='font-bold text-xs ml-3'>{question.label}</label>

            <div>
              {question.questionType === 'MULTI_SELECT' &&
              !question.sublabel ? (
                <div className='blue-text ms-0 mt-2'>
                  <label className='font-semibold text-xs ml-3'>
                    Select all that apply:
                  </label>
                </div>
              ) : question.sublabel ? (
                <div className='blue-text ms-0 mt-2 mb-4 ml-3'>
                  <label className='font-semibold'>{question.sublabel}</label>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>

      {/* col 2 on md sized devices */}
      <div
        className={`${isSurveyQuestion ? 'lg:flex lg:flex-col lg:justify-center lg:align-center lg:pl-10 lg:h-full' : ''}`}
      >
        <div className=''>{renderInputComponent()}</div>
      </div>
    </div>
  );
};

export default Question;
