import { useState, useLayoutEffect, RefObject } from 'react';

function measureTextWidth(text: string, font: string): number {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    throw new Error('Failed to get the canvas context');
  }
  context.font = font;
  return context.measureText(text).width;
}

export function useIsMultiline(
  text: string,
  textRef: RefObject<HTMLSpanElement>,
): boolean {
  const [isMultiline, setIsMultiline] = useState(false);

  useLayoutEffect(() => {
    const updateMultiline = () => {
      if (textRef.current) {
        const computedStyle = window.getComputedStyle(textRef.current);
        const textWidth = measureTextWidth(text, computedStyle.font);
        const containerWidth = textRef.current.offsetWidth;

        const tolerance = 2; // Tolerance in pixels to avoid minor rounding issues
        setIsMultiline(textWidth > containerWidth + tolerance);
      }
    };

    updateMultiline();
    window.addEventListener('resize', updateMultiline);

    return () => {
      window.removeEventListener('resize', updateMultiline);
    };
  }, [text, textRef]);

  return isMultiline;
}
