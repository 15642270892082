import React, { useRef } from 'react';
import { Answer } from '../../../types/answer';
import { useIsMultiline } from '../../../utils/use-is-multiline';

interface CheckboxInputProps {
  name: string;
  value: string;
  checked: boolean;
  onChange: (value: Answer) => void;
  text: string;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({
  name,
  value,
  checked,
  onChange,
  text,
}) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const isMultiline = useIsMultiline(text, textRef);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      questionId: name,
      type: 'ANSWERED',
      value: event.target.checked ? value : `-${value}`, // Indicates addition or removal
    });
  };

  return (
    <label
      className={`flex option-container blurred t-2.5 min-h-[39px] w-full cursor-pointer blurred p-2.5 pl-4 rounded-lg border border-purple font-semibold ${
        checked ? 'checked' : 'not-checked'
      } ${isMultiline ? 'multiline' : ''} mb-3`}
      onClick={(e) => e.stopPropagation()} // Prevent the label from triggering the input again
    >
      <input
        type='checkbox'
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
        className='hidden'
      />
      <span ref={textRef} className='flex-1 flex items-center min-h-[17px]'>
        {text}
      </span>
    </label>
  );
};

export default CheckboxInput;
