export default function VideoBackground() {
  const motionQuery = matchMedia('(prefers-reduced-motion)');

  if (motionQuery.matches) {
    return (
      <>
        <div
          className='fixed block lg:hidden inset-0 bg-cover bg-center'
          style={{ backgroundImage: `url("/images/background-1.png")` }}
        ></div>
        <div
          className='fixed hidden lg:block inset-0 bg-cover bg-center'
          style={{ backgroundImage: `url("/images/background-2.png")` }}
        ></div>
      </>
    );
  } else {
    return (
      <video
        autoPlay
        loop
        muted
        playsInline
        className='fixed inset-0 w-full h-full object-cover video-background'
      >
        <source
          src='/videos/Loop.mp4'
          type='video/mp4'
          media='all and (max-width:1024px)'
        />
        <source src='/videos/LoopDT.mp4' type='video/mp4' />
        Your browser does not support the video tag.
      </video>
    );
  }
}
