import React, { useState, useEffect, useMemo } from 'react';
import { useUser } from '../../../context/UserContext';
import demographicsQuestionnaireData from '../../../data/demographics-questionnaire.json';
import { User } from '../../../types/user';
import Question from '../../Question/Question';
import { Answer } from '../../../types/answer';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Title from '../../Title/Title';
import { ComponentKey } from '../../../types/component-key';
import VerticalLine from '../../VerticalLine/VerticalLine';

interface QuestionnaireChoice {
  value: number | string;
  choiceI18nKey?: string;
}

interface QuestionnaireComponent {
  id: string;
  choices: QuestionnaireChoice[];
}

const createDynamicSchema = (components: QuestionnaireComponent[]) => {
  let schemaFields: { [key: string]: any } = {
    yearsOld: yup
      .number()
      .required('Age is a required field')
      .min(18, 'Age must be at least 18')
      .max(150, 'Age must be less than or equal to 150'),
  };

  components.forEach((component) => {
    schemaFields[component.id] = yup
      .array()
      .of(
        yup.object().shape({
          questionId: yup.string().required(),
          value: yup.mixed().required('This field is required'),
        }),
      )
      .min(1, 'At least one selection is required');
  });

  return yup.object().shape(schemaFields);
};

interface DemographicDetailsProps {
  onNavigate: (componentName: ComponentKey) => void;
}

const DemographicDetails: React.FC<DemographicDetailsProps> = ({
  onNavigate,
}) => {
  const { user, setUser, triggerSave } = useUser();

  const [answers, setAnswers] = useState<Record<string, Answer[]>>({});

  const dynamicSchema = useMemo(
    () =>
      createDynamicSchema(
        demographicsQuestionnaireData.components as QuestionnaireComponent[],
      ),
    [],
  );

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid },
  } = useForm({
    resolver: yupResolver(dynamicSchema),
    mode: 'onBlur',
    defaultValues: {
      yearsOld: undefined,
    },
  });

  useEffect(() => {
    const initialAnswers: Record<string, Answer[]> = {};
    const initialErrors: Record<string, string> = {};
    demographicsQuestionnaireData.components.forEach((component) => {
      initialAnswers[component.id] = []; // Initialize as an empty array of Answer objects
      initialErrors[component.id] = ''; // Initialize error state if needed
    });
    setAnswers(initialAnswers);
  }, []);

  const translations =
    demographicsQuestionnaireData.localizations.find(
      (loc) => loc.locale === 'en_US',
    )?.translations || [];

  const getTranslation = (key: string): string => {
    return translations.find((trans) => trans.key === key)?.translation || key;
  };

  const handleAnswerChange = (answer: Answer) => {
    setAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers };
      // If an answer array for this question already exists, we need to update it
      // instead of appending new answers blindly.
      if (newAnswers[answer.questionId]) {
        // Assuming answer.value is either added or should replace the existing values:
        newAnswers[answer.questionId] = [answer];
      } else {
        // If no answers are recorded for this question, start a new array
        newAnswers[answer.questionId] = [answer];
      }

      return newAnswers;
    });
  };

  const getChoiceTranslationKeys = (
    questionId: string,
    answerValues: Answer[],
  ): string[] => {
    const component = (
      demographicsQuestionnaireData.components as QuestionnaireComponent[]
    ).find((component) => component.id === questionId);
    // Flatten the answer values and ensure they are strings before looking up translation keys
    const values = answerValues
      .flatMap((answer) =>
        Array.isArray(answer.value) ? answer.value : [answer.value],
      )
      .map(String); // Convert all values to strings for consistent key lookups

    return values.map((value) => {
      const choice = (component?.choices ?? []).find(
        (choice: QuestionnaireChoice) => choice.value.toString() === value,
      );
      return choice?.choiceI18nKey || '';
    });
  };

  const handleFormSubmit = async (data: FieldValues) => {
    let hasError = Object.keys(formErrors).length > 0;

    if (hasError) {
      return;
    }

    const { yearsOld } = data ?? 0; // Ensure age defaults to 0 if undefined
    const updatedUser: User = {
      ...user,
      sexAssignedAtBirth: getChoiceTranslationKeys(
        'nil_profile_q01',
        answers['nil_profile_q01'],
      )[0],
      races: getChoiceTranslationKeys(
        'nil_profile_q02',
        answers['nil_profile_q02'],
      ),
      ethnicity: getChoiceTranslationKeys(
        'nil_profile_q03',
        answers['nil_profile_q03'],
      )[0],
      education: getChoiceTranslationKeys(
        'nil_profile_q04',
        answers['nil_profile_q04'],
      )[0],
      yearsOld: yearsOld,
    };

    setUser(updatedUser);
    triggerSave();
    onNavigate('surveyIntro');
  };

  const isFormComplete = () => {
    return Object.keys(answers).every(
      (key) => answers[key].length > 0 && answers[key][0].value !== '',
    );
  };

  const handleBack = () => {
    onNavigate('basicUserInfo' as ComponentKey);
  };

  return (
    <div
      id='demographics'
      className='flex flex-col justify-start lg:justify-center lg:items-center w-full h-full min-h-full'
    >
      <div className='lg:outline lg:outline-white rounded-lg px-12 lg:px-5 pt-8 lg:pt-6 lg:pb-16 w-full lg:w-full lg:max-w-[61.875rem] relative'>
        <div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-6'>
          {/* col 1 on md sized devices */}
          <div className='flex flex-col justify-start lg:justify-center items-center'>
            <Title horizontal={true} />

            <div className='lg:pl-5 h-full flex flex-col justify-center'>
              <div className='text-2xl text-center uppercase'>
                <h1>Details</h1>
              </div>
              <div className='bg-white max-w-80 lg:w-80 mx-auto rounded-lg my-[14px] lg:mt-1 lg:mb-0 blurred px-5 py-5 lg:px-5 lg:py-6'>
                <p className='text-base font-medium text-center lg:text-left  place-content-center leading-5'>
                  By answering a few fun questions we can craft a personalized
                  brain health action plan just for you.
                </p>
              </div>
            </div>
          </div>

          <VerticalLine />

          {/* col 2 on md sized devices */}
          <div className='lg:flex lg:flex-col lg:justify-center lg:align-center lg:pl-14 lg:pr-24 lg:pt-8 mb-20 lg:mb-0'>
            <form onSubmit={handleSubmit(handleFormSubmit)} className='text-sm'>
              <div className='mb-4 relative'>
                <label htmlFor='yearsOld' className='text-xs font-bold ml-3'>
                  Age
                </label>
                <input
                  type='number'
                  {...register('yearsOld')}
                  placeholder='Enter your age'
                  className='w-full px-3 py-1 min-h-[35px] border border-purple rounded-full text-base'
                />
                {formErrors.yearsOld && (
                  <div className='absolute inset-y-0 right-0 items-center pointer-events-none w-6 h-6 rounded-full bg-white flex justify-center align-center mr-2 mt-[1.588rem]'>
                    <img
                      src='/images/icons/!.svg'
                      alt='exclamation mark'
                      className='h-4 lg:h-3'
                    />
                  </div>
                )}
              </div>

              {demographicsQuestionnaireData.components.map(
                (component, index) => (
                  <div
                    key={component.id}
                    className={`mb-4 lg:flex lg:flex-col ${component.id === 'nil_profile_q02' ? 'pl-2' : ''}`}
                  >
                    <Question
                      question={{
                        id: component.id,
                        label: getTranslation(component.promptI18nKey),
                        choices: component.choices.map((choice) => ({
                          value: choice.value.toString(),
                          text: getTranslation(choice.choiceI18nKey),
                        })),
                        questionType: component.type as
                          | 'SINGLE_DROPDOWN_SELECT'
                          | 'MULTI_DROPDOWN_SELECT'
                          | 'SINGLE_SELECT'
                          | 'MULTI_SELECT',
                      }}
                      currentIndex={index}
                      totalQuestions={
                        demographicsQuestionnaireData.components.length
                      }
                      answers={answers[component.id] || []}
                      onChange={handleAnswerChange}
                      bigLabel={false}
                      error={formErrors[component.id]?.message as string}
                      isSurveyQuestion={false}
                    />
                  </div>
                ),
              )}

              <div className='flex flex-col items-center mt-[3.125rem]'>
                <button type='submit' disabled={!isValid || !isFormComplete()}>
                  Continue
                </button>

                <div
                  className='underline text-sm mt-2.5 back-link'
                  onClick={handleBack}
                >
                  Back
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemographicDetails;
