import React from 'react';

const VerticalLine: React.FC = () => {
  return (
    <div
      className='hidden lg:block lg:pt-40 absolute left-1/2 transform -translate-x-1/2 h-full max-h-[60%] w-[12.5px] bottom-[12%] bg-center bg-no-repeat bg-cover'
      style={{ backgroundImage: `url('/images/line-1.png')` }}
    ></div>
  );
};

export default VerticalLine;
